import { Inject, Injectable, Optional } from '@angular/core';
import { UserContext, UserService } from '@features/auth';
import { Subject, combineLatest, startWith } from 'rxjs';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from './analytics.module';

interface IStringIndex {
  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: any;
    CookieInformation: any;
  }
}

interface PaldeskTags {
  has_email: boolean;
  lang: string;
  country: string;
  company_sap_id: string;
  ga_sap_id?: string;
  area_sapid_nr?: string;
  partnertype: number;
}

@Injectable({ providedIn: 'root' })
export class GoogleAnalytics4Service {
  private _measurementId?: string;
  private _paldeskTags: PaldeskTags | undefined;

  private hasCookieConsent$ = new Subject<boolean>();

  constructor(
    @Optional()
    @Inject(GoogleAnalytics4MeasurementId)
    private _id: string,
    @Inject(UserService)
    userService: UserService,
  ) {
    if (this._id) {
      this._measurementId = this._id;
    }

    if (GoogleAnalytics4Module.HAS_COOKIE_CONSENT_HANDLING) {
      combineLatest([
        this.hasCookieConsent$,
        userService.isAuthorized$.pipe(startWith(false)),
      ]).subscribe(([hasCookieConsent, isAuthorized]) => {
        this._fillPaldeskCustomParameters(
          isAuthorized,
          userService.userContext,
        );

        if (hasCookieConsent || isAuthorized) {
          this.upsertGA4();
        } else {
          this.removeGA4();
        }
      });
    } else {
      userService.isAuthorized$.subscribe((isAuthorized) => {
        this._fillPaldeskCustomParameters(
          isAuthorized,
          userService.userContext,
        );
        this.upsertGA4();
      });
    }
  }

  configGroups(mesureId: string, groupName?: string): void {
    if (groupName == null) {
      this._gtag('config', mesureId);
    } else {
      this._gtag('config', mesureId, { groups: groupName });
    }
  }

  event(eventName: string, eventParams?: object): void {
    let params = {};
    if (this._paldeskTags) {
      params = { ...this._paldeskTags };
    }
    if (eventParams) {
      params = { ...params, ...eventParams };
    }

    if (Object.keys(params).length === 0) {
      this._gtag('event', eventName);
    } else {
      this._gtag(
        'event',
        eventName,
        GoogleAnalytics4Service.removeUndefinedProperties(params),
      );
    }
  }

  set(config: any, value: any): void {
    this._gtag('set', config, value);
  }

  async handleCookieConsent(): Promise<void> {
    const hasCookieConsent = window.CookieInformation?.getConsentGivenFor(
      'cookie_cat_statistic',
    );

    this.hasCookieConsent$.next(hasCookieConsent);
  }

  async upsertGA4(): Promise<void> {
    if (!document.getElementById('ga-gtag')) {
      await this._setup();
      this._js();
    }
    this._config();
  }

  removeGA4() {
    if (document.getElementById('ga-gtag')) {
      document.getElementById('ga-gtag')?.remove();
    }
  }

  static removeUndefinedProperties(obj: IStringIndex): object {
    // eslint-disable-next-line prefer-rest-params
    const newObj: IStringIndex = {};

    for (const prop in obj) {
      if (obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') {
        newObj[prop] = obj[prop];
      }
    }

    return newObj;
  }

  private _fillPaldeskCustomParameters(
    isAuthorized: boolean,
    userContext: UserContext,
  ) {
    if (isAuthorized) {
      this._paldeskTags = {
        has_email: !!userContext.email,
        lang: userContext.lang,
        country: userContext.country,
        company_sap_id: userContext.company_sapid,
        ga_sap_id: userContext.ga_sapid,
        area_sapid_nr: userContext.area_sapid_nr,
        partnertype: userContext.partnertype,
      };
    } else {
      this._paldeskTags = undefined;
    }
  }

  private _setup(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._measurementId) {
        console.warn(
          `Measurement ID not set! Only critical on PROD environment.`,
        );
        return;
      }

      const scriptId = `ga-gtag`;
      if (document.getElementById(scriptId)) {
        reject(new Error(`script id ${scriptId} is already loaded`));
      }
      const { head } = document;
      const script = document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.async = true;
      script.onerror = () => {
        reject(`GA4 script load error`);
      };
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        resolve();
      };
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this._measurementId}`;
      head.insertBefore(script, head.firstChild);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _gtag(...arg: any): void {
    if (window.dataLayer) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    } else {
      console.warn(`GoogleAnalytics4Service not initalized.`);
    }
  }

  private _config() {
    if (this._paldeskTags) {
      this._gtag('config', this._measurementId, this._paldeskTags);
    } else {
      this._gtag('config', this._measurementId);
    }
  }

  private _js(date: Date = new Date()): void {
    this._gtag('js', date);
  }
}
