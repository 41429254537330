import {
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { GoogleAnalytics4Service } from './analytics.service';

export const GoogleAnalytics4MeasurementId = new InjectionToken(
  'GA_4_MEASUREMENT_ID',
);

@NgModule({
  imports: [CommonModule],
})
export class GoogleAnalytics4Module {
  static HAS_COOKIE_CONSENT_HANDLING = false;

  constructor(
    private _ga4Service: GoogleAnalytics4Service,
    @Optional() @SkipSelf() parentModule?: GoogleAnalytics4Module,
  ) {
    if (parentModule) {
      throw new Error(
        'GoogleAnalytics4Module is already loaded. Import it in your AppModule only',
      );
    } else if (GoogleAnalytics4Module.HAS_COOKIE_CONSENT_HANDLING) {
      (async () => {
        await this._ga4Service.handleCookieConsent();
      })();
    }
  }

  static forRoot(
    measurementIdProvider: Provider,
    hasCookieConsentHandling = false,
  ): ModuleWithProviders<GoogleAnalytics4Module> {
    GoogleAnalytics4Module.HAS_COOKIE_CONSENT_HANDLING =
      hasCookieConsentHandling;
    return {
      ngModule: GoogleAnalytics4Module,
      providers: [GoogleAnalytics4Service, measurementIdProvider],
    };
  }
}
