/**
 * Mounting Database API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CabSize } from '../model/models';
import { DocumentMetadata } from '../model/models';
import { DocumentType } from '../model/models';
import { MeasuringSystem } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProjectRequest } from '../model/models';
import { ProjectResponse } from '../model/models';
import { ProjectsResponse } from '../model/models';
import { SortingDirection } from '../model/models';
import { VisibilityType } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

    protected basePath = 'https://localhost:44386';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param id 
     * @param documentType 
     * @param visibilityType 
     * @param document 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocument(id: number, documentType: DocumentType, visibilityType: VisibilityType, document: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<DocumentMetadata>;
    public createDocument(id: number, documentType: DocumentType, visibilityType: VisibilityType, document: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<DocumentMetadata>>;
    public createDocument(id: number, documentType: DocumentType, visibilityType: VisibilityType, document: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<DocumentMetadata>>;
    public createDocument(id: number, documentType: DocumentType, visibilityType: VisibilityType, document: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createDocument.');
        }
        if (documentType === null || documentType === undefined) {
            throw new Error('Required parameter documentType was null or undefined when calling createDocument.');
        }
        if (visibilityType === null || visibilityType === undefined) {
            throw new Error('Required parameter visibilityType was null or undefined when calling createDocument.');
        }
        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling createDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentType !== undefined && documentType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentType, 'document_type');
        }
        if (visibilityType !== undefined && visibilityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>visibilityType, 'visibility_type');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (document !== undefined) {
            formParams = formParams.append('document', <any>document) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DocumentMetadata>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}/documents`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param projectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProject(projectRequest: ProjectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProjectResponse>;
    public createProject(projectRequest: ProjectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProjectResponse>>;
    public createProject(projectRequest: ProjectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProjectResponse>>;
    public createProject(projectRequest: ProjectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (projectRequest === null || projectRequest === undefined) {
            throw new Error('Required parameter projectRequest was null or undefined when calling createProject.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProjectResponse>(`${this.configuration.basePath}/projects`,
            projectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocument(id: number, documentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public deleteDocument(id: number, documentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public deleteDocument(id: number, documentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public deleteDocument(id: number, documentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocument.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProject(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public deleteProject(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public deleteProject(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public deleteProject(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProject.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param crane 
     * @param hookloader 
     * @param skiploader 
     * @param mountingArea 
     * @param installationType 
     * @param vehicleManufacturer 
     * @param modelRange 
     * @param wheelFormula 
     * @param cabSizes 
     * @param emissionStandard 
     * @param loadMaxFrom 
     * @param loadMaxTo 
     * @param measuringSystem 
     * @param additionalSupport 
     * @param flyjib 
     * @param createDateFrom 
     * @param createDateTo 
     * @param visibility 
     * @param searchQuery 
     * @param numberOfResults 
     * @param page 
     * @param columnNameToSort 
     * @param sortingDirection 
     * @param bodyInsideLengthFrom 
     * @param bodyInsideLengthTo 
     * @param documentTypes 
     * @param craneLiftingPowerFrom 
     * @param craneLiftingPowerTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Blob>;
    public exportProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Blob>>;
    public exportProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Blob>>;
    public exportProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (crane) {
            crane.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'crane');
            })
        }
        if (hookloader) {
            hookloader.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'hookloader');
            })
        }
        if (skiploader) {
            skiploader.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'skiploader');
            })
        }
        if (mountingArea) {
            mountingArea.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'mounting_area');
            })
        }
        if (installationType) {
            installationType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'installation_type');
            })
        }
        if (vehicleManufacturer) {
            vehicleManufacturer.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'vehicle_manufacturer');
            })
        }
        if (modelRange) {
            modelRange.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'model_range');
            })
        }
        if (wheelFormula) {
            wheelFormula.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'wheel_formula');
            })
        }
        if (cabSizes) {
            cabSizes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'cab_sizes');
            })
        }
        if (emissionStandard) {
            emissionStandard.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'emission_standard');
            })
        }
        if (loadMaxFrom !== undefined && loadMaxFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>loadMaxFrom, 'load_max_from');
        }
        if (loadMaxTo !== undefined && loadMaxTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>loadMaxTo, 'load_max_to');
        }
        if (measuringSystem !== undefined && measuringSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>measuringSystem, 'measuring_system');
        }
        if (additionalSupport) {
            additionalSupport.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'additional_support');
            })
        }
        if (flyjib) {
            flyjib.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'flyjib');
            })
        }
        if (createDateFrom !== undefined && createDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createDateFrom, 'create_date_from');
        }
        if (createDateTo !== undefined && createDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createDateTo, 'create_date_to');
        }
        if (visibility) {
            visibility.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'visibility');
            })
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchQuery, 'search_query');
        }
        if (numberOfResults !== undefined && numberOfResults !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>numberOfResults, 'number_of_results');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (columnNameToSort !== undefined && columnNameToSort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>columnNameToSort, 'column_name_to_sort');
        }
        if (sortingDirection !== undefined && sortingDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortingDirection, 'sorting_direction');
        }
        if (bodyInsideLengthFrom !== undefined && bodyInsideLengthFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bodyInsideLengthFrom, 'body_inside_length_from');
        }
        if (bodyInsideLengthTo !== undefined && bodyInsideLengthTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bodyInsideLengthTo, 'body_inside_length_to');
        }
        if (documentTypes) {
            documentTypes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'document_types');
            })
        }
        if (craneLiftingPowerFrom !== undefined && craneLiftingPowerFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>craneLiftingPowerFrom, 'crane_lifting_power_from');
        }
        if (craneLiftingPowerTo !== undefined && craneLiftingPowerTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>craneLiftingPowerTo, 'crane_lifting_power_to');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/projects/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocument(id: number, documentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<DocumentMetadata>;
    public getDocument(id: number, documentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<DocumentMetadata>>;
    public getDocument(id: number, documentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<DocumentMetadata>>;
    public getDocument(id: number, documentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocument.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DocumentMetadata>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProject(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProjectResponse>;
    public getProject(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProjectResponse>>;
    public getProject(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProjectResponse>>;
    public getProject(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProject.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProjectResponse>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param crane 
     * @param hookloader 
     * @param skiploader 
     * @param mountingArea 
     * @param installationType 
     * @param vehicleManufacturer 
     * @param modelRange 
     * @param wheelFormula 
     * @param cabSizes 
     * @param emissionStandard 
     * @param loadMaxFrom 
     * @param loadMaxTo 
     * @param measuringSystem 
     * @param additionalSupport 
     * @param flyjib 
     * @param createDateFrom 
     * @param createDateTo 
     * @param visibility 
     * @param searchQuery 
     * @param numberOfResults 
     * @param page 
     * @param columnNameToSort 
     * @param sortingDirection 
     * @param bodyInsideLengthFrom 
     * @param bodyInsideLengthTo 
     * @param documentTypes 
     * @param craneLiftingPowerFrom 
     * @param craneLiftingPowerTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProjectsResponse>;
    public getProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProjectsResponse>>;
    public getProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProjectsResponse>>;
    public getProjects(crane?: Array<string>, hookloader?: Array<string>, skiploader?: Array<string>, mountingArea?: Array<number>, installationType?: Array<number>, vehicleManufacturer?: Array<string>, modelRange?: Array<string>, wheelFormula?: Array<string>, cabSizes?: Array<CabSize>, emissionStandard?: Array<string>, loadMaxFrom?: number, loadMaxTo?: number, measuringSystem?: MeasuringSystem, additionalSupport?: Array<string>, flyjib?: Array<string>, createDateFrom?: Date, createDateTo?: Date, visibility?: Array<VisibilityType>, searchQuery?: string, numberOfResults?: number, page?: number, columnNameToSort?: string, sortingDirection?: SortingDirection, bodyInsideLengthFrom?: number, bodyInsideLengthTo?: number, documentTypes?: Array<DocumentType>, craneLiftingPowerFrom?: number, craneLiftingPowerTo?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (crane) {
            crane.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'crane');
            })
        }
        if (hookloader) {
            hookloader.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'hookloader');
            })
        }
        if (skiploader) {
            skiploader.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'skiploader');
            })
        }
        if (mountingArea) {
            mountingArea.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'mounting_area');
            })
        }
        if (installationType) {
            installationType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'installation_type');
            })
        }
        if (vehicleManufacturer) {
            vehicleManufacturer.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'vehicle_manufacturer');
            })
        }
        if (modelRange) {
            modelRange.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'model_range');
            })
        }
        if (wheelFormula) {
            wheelFormula.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'wheel_formula');
            })
        }
        if (cabSizes) {
            cabSizes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'cab_sizes');
            })
        }
        if (emissionStandard) {
            emissionStandard.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'emission_standard');
            })
        }
        if (loadMaxFrom !== undefined && loadMaxFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>loadMaxFrom, 'load_max_from');
        }
        if (loadMaxTo !== undefined && loadMaxTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>loadMaxTo, 'load_max_to');
        }
        if (measuringSystem !== undefined && measuringSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>measuringSystem, 'measuring_system');
        }
        if (additionalSupport) {
            additionalSupport.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'additional_support');
            })
        }
        if (flyjib) {
            flyjib.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'flyjib');
            })
        }
        if (createDateFrom !== undefined && createDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createDateFrom, 'create_date_from');
        }
        if (createDateTo !== undefined && createDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createDateTo, 'create_date_to');
        }
        if (visibility) {
            visibility.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'visibility');
            })
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchQuery, 'search_query');
        }
        if (numberOfResults !== undefined && numberOfResults !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>numberOfResults, 'number_of_results');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (columnNameToSort !== undefined && columnNameToSort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>columnNameToSort, 'column_name_to_sort');
        }
        if (sortingDirection !== undefined && sortingDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortingDirection, 'sorting_direction');
        }
        if (bodyInsideLengthFrom !== undefined && bodyInsideLengthFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bodyInsideLengthFrom, 'body_inside_length_from');
        }
        if (bodyInsideLengthTo !== undefined && bodyInsideLengthTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bodyInsideLengthTo, 'body_inside_length_to');
        }
        if (documentTypes) {
            documentTypes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'document_types');
            })
        }
        if (craneLiftingPowerFrom !== undefined && craneLiftingPowerFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>craneLiftingPowerFrom, 'crane_lifting_power_from');
        }
        if (craneLiftingPowerTo !== undefined && craneLiftingPowerTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>craneLiftingPowerTo, 'crane_lifting_power_to');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProjectsResponse>(`${this.configuration.basePath}/projects`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchProject(projectId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public searchProject(projectId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public searchProject(projectId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public searchProject(projectId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (projectId !== undefined && projectId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>projectId, 'project_id');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/projects/search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param documentId 
     * @param visibilityType 
     * @param document 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocument(id: number, documentId: string, visibilityType: VisibilityType, document?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<DocumentMetadata>;
    public updateDocument(id: number, documentId: string, visibilityType: VisibilityType, document?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<DocumentMetadata>>;
    public updateDocument(id: number, documentId: string, visibilityType: VisibilityType, document?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<DocumentMetadata>>;
    public updateDocument(id: number, documentId: string, visibilityType: VisibilityType, document?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateDocument.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling updateDocument.');
        }
        if (visibilityType === null || visibilityType === undefined) {
            throw new Error('Required parameter visibilityType was null or undefined when calling updateDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (visibilityType !== undefined && visibilityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>visibilityType, 'visibility_type');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (document !== undefined) {
            formParams = formParams.append('document', <any>document) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<DocumentMetadata>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}/documents/${encodeURIComponent(String(documentId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param projectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProject(id: number, projectRequest: ProjectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProjectResponse>;
    public updateProject(id: number, projectRequest: ProjectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProjectResponse>>;
    public updateProject(id: number, projectRequest: ProjectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProjectResponse>>;
    public updateProject(id: number, projectRequest: ProjectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProject.');
        }
        if (projectRequest === null || projectRequest === undefined) {
            throw new Error('Required parameter projectRequest was null or undefined when calling updateProject.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ProjectResponse>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(id))}`,
            projectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
