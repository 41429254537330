import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsSnackbar } from './snackbar';
import { DsSnackbarComponent } from './snackbar.component';

@NgModule({
  declarations: [DsSnackbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
  providers: [DsSnackbar],
})
export class DsSnackbarModule {}
