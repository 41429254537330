import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  tableSettingsKey,
  TableSettingsState,
} from './table-settings.reducers';

const selectTableSettings =
  createFeatureSelector<TableSettingsState>(tableSettingsKey);

export namespace TableSettingsSelectors {
  export const getTableSettings = createSelector(
    selectTableSettings,
    (state, props) => state.userSettings?.[props.id],
  );
  export const getIsTableSettingsLoading = createSelector(
    selectTableSettings,
    (state) => state.isUserSettingsLoading,
  );
}
