import { Injectable, Optional, SkipSelf } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableSettingsIntl {
  getColumnName = (column_name: string) => column_name;
}

export function TABLE_SETTINGS_INTL_PROVIDER_FACTORY(
  parentIntl: TableSettingsIntl,
) {
  return parentIntl || new TableSettingsIntl();
}

export const TABLE_SETTINGS_INTL_PROVIDER = {
  provide: TableSettingsIntl,
  deps: [[new Optional(), new SkipSelf(), TableSettingsIntl]],
  useFactory: TABLE_SETTINGS_INTL_PROVIDER_FACTORY,
};
