import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import {
  GaService,
  GoogleAnalyticsTrackingId,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { BASE_PATH } from './shared/generated';

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'Mounting Database',
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BASE_PATH,
      useFactory: () =>
        environment.palfinger_api_management + '/mounting-database',
    },
    // analytics
    GaService,
    {
      provide: GoogleAnalyticsTrackingId,
      useFactory: () => environment.google_analytics_id,
    },
    // table settings
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // help pages
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/ms-wiki',
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/Help pages/Mounting Database',
          projectName: 'Palfinger.Paldesk',
          wikiName: 'Palfinger.Paldesk.wiki',
          appTitle: 'Mounting Database',
        }) as HelpPagesConfig,
    },
  ],
})
export class CoreModule {}
