import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import {
  IMessage,
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';

export class ErrorObject {
  error: any;
  request?: HttpRequest<any>;
  requestHeaders?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorClassificatorService {
  constructor(
    private messenger: MessengerService,
    private snackbar: DsSnackbar,
  ) {}

  processError(error: any, req?: HttpRequest<any>) {
    let message = 'Something happened, but we are not sure what';
    let source = 'Unknown';
    const errorObject: ErrorObject = {
      error: error,
      request: req,
      requestHeaders: {},
    };

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        message = '401 Unauthorized';
      } else {
        message = error.message;
      }
      source = 'HttpErrorResponse';
    } else if (error instanceof TypeError) {
      message = error.message;
      source = 'TypeError';
    } else if (error instanceof Error) {
      if (error.name === 'TimeoutError') {
        message = 'There happened a TimeoutError';
        source = 'TimeoutError';
      } else {
        message = 'There happened a general Error';
        source = 'General Error';
      }
    }

    const targets = [MessageTargetType.console, MessageTargetType.log];
    if (error.status === 401) {
      targets.push(MessageTargetType.toast);
    }

    const notification: IMessage<any> = {
      severity: MessageSeverityType.error,
      message: message,
      targets: targets,
      detailObject: errorObject,
      time: new Date(),
      source: source,
    };

    if (req) {
      notification.url = req.urlWithParams;
      if (req.headers) {
        const keys = req.headers.keys();
        // eslint-disable-next-line @typescript-eslint/ban-types
        const transformedHeaders: object[] = [];
        keys.forEach((headerKey) => {
          const headerValues = req.headers.getAll(headerKey);
          transformedHeaders.push({ key: headerKey, values: headerValues });
        });
        errorObject.requestHeaders = transformedHeaders;
      }
    }

    this.messenger.sendDetailMessage(notification);
    if (error.status === 401) {
      this.snackbar.queue(notification.message, {
        type: DsSnackbarType.Error,
      });
    }
  }
}
