import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { TableSettingsIntl } from '@design-system/components/table-settings';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TableSettingsI18n } from './i18n/table-settings-i18n';
import { ConvertToMeasuringUnitLengthPipe } from './pipes/convert-to-measuring-unit-length/convert-to-measuring-unit-length.pipe';
import { ConvertToMeasuringUnitWeightPipe } from './pipes/convert-to-measuring-unit-weight/convert-to-measuring-unit-weight.pipe';
import { MapPicklistResponseToFilterValuePipe } from './pipes/mapPicklistResponseToFilterValue/map-picklist-response-to-filter-value.pipe';
import { MapTranslatablePicklistResponseToFilterValuePipe } from './pipes/mapTranslatablePicklistResponseToFilterValue/map-translatable-picklist-response-to-filter-value.pipe';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';

export function getTableSettingsIntl(translateService: TranslateService) {
  return new TableSettingsI18n(translateService).getIntl();
}

const sharedModules = [
  DsSpacingModule,
  FlexLayoutModule,
  TranslateModule,
  DsTableModule,
  DsTableLoadingModule,
  MatButtonModule,
  MatIconModule,
  MatTableModule,
  MatTooltipModule,
  DsSnackbarModule,
  DsLoadingModule,
  GoogleAnalyticsClickListenerDirective,
];

@NgModule({
  declarations: [
    MapTranslatablePicklistResponseToFilterValuePipe,
    MapPicklistResponseToFilterValuePipe,
    ConvertToMeasuringUnitWeightPipe,
    ConvertToMeasuringUnitLengthPipe,
  ],
  imports: sharedModules,
  exports: [
    ...sharedModules,
    MapTranslatablePicklistResponseToFilterValuePipe,
    MapPicklistResponseToFilterValuePipe,
    ConvertToMeasuringUnitWeightPipe,
    ConvertToMeasuringUnitLengthPipe,
  ],
  providers: [
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: getTableSettingsIntl,
    },
  ],
})
export class SharedModule {}
