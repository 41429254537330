import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { SharedModule } from './shared/shared.module';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import { AppWrapperModule } from '@design-system/feature/app-wrapper-v2';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appInsightsRole: 'FE: Mounting Database',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appHelpPath: 'help',
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
          appName: 'Mounting Database',
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsPageModule,
    DsBreadcrumbsModule,
    CoreModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
  ],
  providers: [
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
