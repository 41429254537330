<a routerLink="/help" routerLinkActive #rla="routerLinkActive"></a>
<ds-app-wrapper routerLinkActive="active" #rla="routerLinkActive">
  <ds-breadcrumbs
    *ngIf="rla.isActive"
    baseUrl="/"
    baseLabel="Mounting Database"
  >
  </ds-breadcrumbs>
  <div fxFlex="0 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
