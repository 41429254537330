<div
  id="wrapper"
  class="{{ type }}"
  fxLayout
  fxLayoutAlign="space-between center"
  [fxLayoutGap]="0.5 | dsSpacing"
>
  <ds-text-indicator matSnackBarLabel [type]="type" [noColor]="true">{{
    message
  }}</ds-text-indicator>
  <ng-container *ngIf="action && snackbarRef">
    <button
      matSnackBarAction
      *ngIf="!href; else link"
      mat-button
      color="white"
      (click)="snackbarRef.dismissWithAction()"
    >
      {{ action }}
    </button>
    <ng-template #link>
      <a
        matSnackBarAction
        mat-button
        (click)="snackbarRef.dismissWithAction()"
        [href]="href"
        color="white"
        target="_blank"
      >
        {{ action }}
      </a>
    </ng-template>
  </ng-container>
</div>
