import { createAction, props } from '@ngrx/store';
import { TableSettings } from '@design-system/components/table-settings';

export namespace TableSettingsActions {
  export const LoadUserTableSettings = createAction(
    '[TABLE SETTINGS] LOAD_USER_TABLE_SETTINGS',
    props<{ allColumns: TableSettings[]; settingsKey: string }>(),
  );

  export const LoadUserTableSettingsSuccess = createAction(
    '[TABLE SETTINGS] LOAD_USER_TABLE_SETTINGS_SUCCESS',
    props<{ payload: TableSettings[]; settingsKey: string }>(),
  );
}
