import { TableSettings } from '@design-system/components/table-settings';
import { createReducer, on } from '@ngrx/store';
import { TableSettingsActions } from './table-settings.actions';

export const tableSettingsKey = 'tableSettings';

export interface TableSettingsState {
  userSettings: { [key: string]: TableSettings[] };
  isUserSettingsLoading: boolean;
}

const initialState: TableSettingsState = {
  userSettings: {},
  isUserSettingsLoading: true,
};

const _tableSettings = createReducer(
  initialState,
  on(TableSettingsActions.LoadUserTableSettings, (state, { settingsKey }) => ({
    ...state,
    isUserSettingsLoading:
      state.userSettings?.[settingsKey] ===
      initialState.userSettings?.[settingsKey],
  })),
  on(
    TableSettingsActions.LoadUserTableSettingsSuccess,
    (state, { payload, settingsKey }) => ({
      ...state,
      isUserSettingsLoading: false,
      userSettings: { ...state.userSettings, [settingsKey]: payload },
    }),
  ),
);

export function tableSettingsReducer(state, action) {
  return _tableSettings(state, action);
}
