import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule, MediaObserver } from '@angular/flex-layout';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { dsAnimations } from '@design-system/cdk/animations';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { UserContextService } from '@features/auth';
import { TranslateModule } from '@ngx-translate/core';
import {
  SuggestionHistoryItemResponse,
  SuggestionItemResponse,
  SuggestionItemResponseCategory,
  SuggestionService,
} from '@paldesk/shared-lib/data-access/search-generated';
import {
  GoogleAnalytics4DirectiveModule,
  GoogleAnalytics4Service,
} from '@shared-lib/google-analytics';
import { filterTruthy } from '@shared-lib/rxjs';
import { TypedQueryParamsService } from '@shared-lib/typed-query-params';
import { catchError, concatMap, debounceTime, of, switchMap } from 'rxjs';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../../config';
import { gAAppWrapperV2Constants } from '../../shared/model';
import {
  CATEGORY_QUERY_PARAM_NAME,
  DOCUMENT_TYPE_PARAM_NAME,
  PRODLINE_QUERY_PARAM_NAME,
  PRODUCED_QUERY_PARAM_NAME,
  SEARCH_RESULT_PAGE_PATH_NAME,
  SEARCH_TERM_QUERY_PARAM_NAME,
} from '../constants';
import { SanitizeService } from '../sanitize.service';
import { SuggestionComponent } from './suggestion/suggestion.component';

@Component({
  selector: 'ds-new-paldesk-search-menu',
  standalone: true,
  imports: [
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    DsSpacingModule,
    MatAutocompleteModule,
    MatRippleModule,
    SuggestionComponent,
    GoogleAnalytics4DirectiveModule,
  ],
  animations: [
    matExpansionAnimations.indicatorRotate,
    dsAnimations.rotate180,
    dsAnimations.rotate90,
  ],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'search-suggestions-autocomplete-opened' },
    },
  ],
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchMenuComponent {
  @ViewChild('suggestionsMenuTrigger')
  autoCompleteTrigger: MatAutocompleteTrigger;

  searchFormControl: FormControl<string | null>;

  history: WritableSignal<SuggestionHistoryItemResponse[]> = signal([]);
  suggestions: WritableSignal<SuggestionItemResponse[]> = signal([]);

  categoryParam: Array<'Document' | 'Equipment' | 'Product' | 'Sparepart'>;
  productLineParam: string[];
  documentTypeParam: string[];
  producedParam: boolean[];

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  constructor(
    public media: MediaObserver,
    @Inject(DS_APP_WRAPPER_CONFIG) private _config: DsAppWrapperConfig,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _suggestionService: SuggestionService,
    private _typedQueryParamsService: TypedQueryParamsService,
    private _sanitizeService: SanitizeService,
    private _ga4service: GoogleAnalytics4Service,
    private _userContextService: UserContextService,
  ) {
    _suggestionService.configuration.basePath = `${_config.appGatewayBasePath}/search`;

    this.searchFormControl = new FormControl<string | null>('');

    this._activatedRoute.queryParams
      .pipe(takeUntilDestroyed())
      .subscribe((queryParams) => {
        this.searchFormControl.setValue(
          queryParams[SEARCH_TERM_QUERY_PARAM_NAME] ??
            this.searchFormControl.value?.trim(),
          {
            emitEvent: false,
          },
        );
      });

    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(300),
        filterTruthy(),
        switchMap((value) => {
          this.getFilterParams();
          return this._suggestionService.suggestionGet(
            _sanitizeService.sanitizeSearchTermHtmlDecode(value),
            this.categoryParam,
            this.productLineParam,
            this.documentTypeParam,
            this.producedParam,
          );
        }),
        catchError(() => of({ history: [], suggestions: [] })),
        takeUntilDestroyed(),
      )
      .subscribe((suggestions) => {
        this.history.set(suggestions.history);
        this.suggestions.set(
          suggestions.suggestions.sort(
            (a: SuggestionItemResponse, b: SuggestionItemResponse) => {
              const order = [
                SuggestionItemResponseCategory.Equipment,
                SuggestionItemResponseCategory.Sparepart,
                SuggestionItemResponseCategory.Product,
                SuggestionItemResponseCategory.Document,
              ];

              return order.indexOf(a.category) - order.indexOf(b.category);
            },
          ),
        );
      });
  }

  getSuggestions() {
    this.getFilterParams();
    this._suggestionService
      .suggestionGet(
        this._sanitizeService.sanitizeSearchTermHtmlDecode(
          this.searchFormControl.value,
        ),
        this.categoryParam,
        this.productLineParam,
        this.documentTypeParam,
        this.producedParam,
      )
      .subscribe((suggestions) => {
        this.history.set(suggestions.history);
        this.suggestions.set(suggestions.suggestions);
      });
  }

  doSearch(suggestionCategory?: string) {
    if (this.autoCompleteTrigger && this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }

    const searchTerm = this.searchFormControl.value?.trim();
    if (this.searchFormControl.value) {
      this._ga4service.event(
        this.googleAnalyticsConstants.header.otherEvents.search,
      );

      const currentLocation = new URL(window.location.href);
      const searchResultPageLocation = new URL(
        `${SEARCH_RESULT_PAGE_PATH_NAME}?${SEARCH_TERM_QUERY_PARAM_NAME}=${encodeURI(searchTerm ?? '')}`,
        this._config.paldeskBasePath,
      );
      // if not on paldesk origin, navigate to https://paldesk.palfinger.com/search_result?search_term={searchTerm}
      if (currentLocation.origin !== searchResultPageLocation.origin) {
        if (
          this._userContextService.userContext?.search_result_page_current_tab
        ) {
          window.open(searchResultPageLocation.href, '_self');
        } else {
          window.open(searchResultPageLocation.href, '_blank');
        }
      } else {
        const queryParams = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          search_term: searchTerm,
          // Conditionally add the category parameter if suggestionCategory is not undefined
          ...(suggestionCategory !== undefined && {
            category: `string(${`[${suggestionCategory.toLowerCase()}]`})`,
          }),
        };
        // if you are on paldesk search result page or on some other subpage like CMI or PAT (not on search-result page), navigate to search-result page
        this._router.navigate([searchResultPageLocation.pathname], {
          queryParamsHandling: 'merge',
          queryParams,
        });
      }
    }
  }

  deleteHistorySearchTerm(event: Event, searchTerm: string) {
    event.stopPropagation();
    this.getFilterParams();

    this._suggestionService
      .suggestionDelete(searchTerm)
      .pipe(
        concatMap(() =>
          this._suggestionService.suggestionGet(
            this._sanitizeService.sanitizeSearchTermHtmlDecode(
              this.searchFormControl.value,
            ),
            this.categoryParam,
            this.productLineParam,
            this.documentTypeParam,
            this.producedParam,
          ),
        ),
      )
      .subscribe((suggestions) => {
        this.history.set(suggestions.history);
        this.suggestions.set(suggestions.suggestions);
      });
  }

  searchForSuggestion($event: MatAutocompleteSelectedEvent) {
    // see SuggestionHistoryItemResponse or SuggestionItemResponse, where both have prop 'search_term' but only SuggestionItemResponse has 'category' prop
    const decodedHtml = this.decodeHtmlEntities(
      $event.option.value['search_term'],
    );
    this.searchFormControl.setValue(decodedHtml);
    this.doSearch($event.option.value['category']);
  }

  getFilterParams() {
    this.categoryParam =
      this._typedQueryParamsService.get<
        Array<'Document' | 'Equipment' | 'Product' | 'Sparepart'>
      >(CATEGORY_QUERY_PARAM_NAME) ?? '';
    this.productLineParam = this._typedQueryParamsService.get<string[]>(
      PRODLINE_QUERY_PARAM_NAME,
    );

    this.producedParam = this._typedQueryParamsService.get<boolean[]>(
      PRODUCED_QUERY_PARAM_NAME,
    );

    this.documentTypeParam = this._typedQueryParamsService.get<string[]>(
      DOCUMENT_TYPE_PARAM_NAME,
    );
  }

  decodeHtmlEntities(str: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  }
}
