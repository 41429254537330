export enum EquipmentTypes {
  AdditionalStabilizer = 'Z11',
  BarrierLifters = 'Z20',
  BottleBankDischargeUnit = 'Z19',
  Brickstackgrapples = 'Z18',
  Earthauger = 'Z21',
  Electricalbox = 'Z22',
  Flyjib = '014',
  Grapple = 'Z24',
  HydraulicHook = 'Z23',
  Keyholesaw = 'Z25',
  LoadingPlatform = 'Z13',
  Manipulator = 'Z26',
  Mulcher = 'Z27',
  OilCooler = 'Z14',
  Palcover = '025',
  Palletfork = 'Z28',
  Pump = 'Z15',
  Ropewinch = 'Z10',
  Rotator = 'Z12',
  Snowblower = 'Z29',
  Tank = 'Z16',
  Underrideprotection = 'Z17',
  Vacuumequipment = 'Z30',
  Workmanbasket = '051',
}
