/**
 * Palfinger.Microservice.Search
 * Search API
 *
 * The version of the OpenAPI document: v1
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SuggestionItemResponse { 
    
    category: SuggestionItemResponseCategory;
    
    search_term?: string | null;
    
    hint?: string | null;
}
export enum SuggestionItemResponseCategory {
    Document = 'Document',
    Equipment = 'Equipment',
    Product = 'Product',
    Sparepart = 'Sparepart'
};



