import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { MD_ROLES } from './shared/auth/mounting-database-roles';
import { inject } from '@angular/core';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: MD_ROLES,
      } as AuthPermissions,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'projects',
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects/projects.module').then((m) => m.ProjectsModule),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('@paldesk/design-system/feature/help-page').then(
            (m) => m.DSHelpPageModule,
          ),
        data: {
          breadcrumb: 'general.help',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
