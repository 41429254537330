import { Injectable } from '@angular/core';
import { UserService } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DisabledPositionType, TableSettings } from '../models';

const identityServerApiVersion = '2.0';

@Injectable({
  providedIn: 'root',
})
export class TableSettingsService {
  constructor(private userService: UserService) {}

  get(
    key: string,
    defaultSetting: TableSettings[],
  ): Observable<TableSettings[]> {
    return this.userService.getUserSetting(key, identityServerApiVersion).pipe(
      map((result) => {
        if (result?.value && result.value instanceof Array) {
          const userSetting = result.value;
          // all disabled columns from default setting
          const disabledStartColumns: TableSettings[] = defaultSetting.filter(
            (column) => column.disabledPosition === DisabledPositionType.Start,
          );
          const disabledEndColumns: TableSettings[] = defaultSetting.filter(
            (column) => column.disabledPosition === DisabledPositionType.End,
          );

          const disabledKeepColumns: TableSettings[] = defaultSetting.filter(
            (column) => column.disabledPosition === DisabledPositionType.Keep,
          );

          // columns from user setting which are not disabled and exist in default setting
          const otherColumns: TableSettings[] = userSetting
            .filter((userSettingColumn) =>
              defaultSetting.some(
                (defaultSettingColumn) =>
                  defaultSettingColumn.name === userSettingColumn.name &&
                  userSettingColumn.disabledPosition !==
                    DisabledPositionType.Start &&
                  userSettingColumn.disabledPosition !==
                    DisabledPositionType.End,
              ),
            )
            .map((userSettingColumn) => ({
              ...(userSettingColumn as TableSettings),
              disabledPosition: disabledKeepColumns.some(
                (col) =>
                  col.name === userSettingColumn.name && col.name !== 'actions',
              )
                ? DisabledPositionType.Keep
                : undefined,
            }));

          // columns from default setting, which are not in user setting
          const newColumns: TableSettings[] = defaultSetting.filter(
            (defaultSettingColumn) =>
              !defaultSettingColumn.disabledPosition &&
              !otherColumns.some(
                (column) => column.name === defaultSettingColumn.name,
              ),
          );

          return disabledStartColumns
            .concat(otherColumns)
            .concat(newColumns)
            .concat(disabledEndColumns);
        } else {
          // if user setting is not valid, return the default setting
          return defaultSetting;
        }
      }),
      // in case of error, return the default setting
      catchError(() => of(defaultSetting)),
    );
  }

  set(key: string, setting: TableSettings[]): Observable<unknown> {
    const userSettings: TableSettings[] = setting.map((column) => ({
      name: column.name,
      selected: column.selected,
      disabledPosition: column.disabledPosition,
      partnerType: column.partnerType,
    }));

    return this.userService.addOrUpdateUserSetting({
      settingKey: key,
      value: userSettings,
    });
  }
}
