import { PARTNERTYPES } from '@config';

export interface TableSettings {
  name: string;
  selected: boolean;
  partnerType?: PARTNERTYPES;
  disabledPosition?: DisabledPositionType;
}

export enum DisabledPositionType {
  Start = 'Start',
  End = 'End',
  Keep = 'Keep',
}
